import type { FormInstance } from 'antd';
import { Form, Input, Modal, Upload } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import { getDefaultFileList, uploadFileListToCdn, validateFileList } from '../../utils';

interface ExchangeFlagProps {
  mainItem: { brandName?: string; shopId: string; logoUrl?: string; };
  callback: (params: { brandName?: string; shopId?: string; logoUrl?: string; }) => Promise<unknown>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
}

export const ExchangeFlag: React.FC<ExchangeFlagProps> = function({
  mainItem,
  onCancel,
  callback,
}) {
  const [
    loading,
    setLoading,
  ] = React.useState<boolean>(false);
  const formRef = React.useRef<FormInstance>();
  const [
    fileList,
    setFileList,
  ] = React.useState<UploadFile[]>(getDefaultFileList(mainItem?.logoUrl));

  async function handleFinish(params: { brandName: string; shopId: string; }) {
    setLoading(true);

    try {
      const logoUrl = await uploadFileListToCdn({
        obsConfigId: 2,
        fileList,
      });

      await callback({
        ...params,
        logoUrl: logoUrl.join(''),
      });
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading }}
      onCancel={() => onCancel()}
      onOk={() => formRef.current.submit()}
      title="品牌信息"
      visible
      width={500}
    >
      <Form
        initialValues={{
          shopId: mainItem.shopId,
          brandName: mainItem.brandName,
        }}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        ref={formRef}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="店铺id"
          name="shopId"
          style={{ display: 'none' }}
        >
          <Input
            type="hidden"
          />
        </Form.Item>
        <Form.Item
          label="品牌名称"
          name="brandName"
          rules={[
            {
              required: true,
              message: '请输入品牌名称',
            },
          ]}
        >
          <Input
            maxLength={30}
            placeholder="请输入品牌名称"
          />
        </Form.Item>
        <Form.Item
          label="品牌logo"
          name="logoUrl"
          rules={[
            {
              validator: () => validateFileList({ fileList }),
              required: true,
            },
          ]}
        >
          <Upload
            accept=".png,.jpg,.jpeg"
            beforeUpload={() => false}
            fileList={fileList}
            listType="picture-card"
            maxCount={1}
            onChange={(info) => setFileList([...info?.fileList].filter(Boolean))}
          >
            <div className="egenie-common-upload-container">
              <i className="icon-add"/>
              <footer>
                上传图片
              </footer>
            </div>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
