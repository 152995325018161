import { Form, Modal, Select } from 'antd';
import type { ValueAndLabelData } from 'egenie-utils';
import React from 'react';

interface SyncShopModalProps {
  ownerData: ValueAndLabelData;
  callback: (params: { ownerId: ValueAndLabelData; }) => Promise<unknown>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
}

export function SyncShopModal({
  callback,
  onCancel,
  ownerData,
}: SyncShopModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: { ownerId: ValueAndLabelData; }; }>({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="一键同步"
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{}}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="货主"
          name="ownerId"
          rules={[
            {
              required: true,
              message: '请选择货主',
            },
          ]}
        >
          <Select
            allowClear
            mode="multiple"
            optionFilterProp="label"
            options={ownerData}
            placeholder="请选择货主"
            showSearch
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
