import { nanoid } from 'nanoid';
import type { UploadFile } from 'antd/lib/upload/interface';
import { singleUpload } from 'egenie-common';

export function getDefaultFileList(urlList: string | string[], type = 'image/png'): UploadFile[] {
  return [].concat(urlList)
    .filter(Boolean)
    .map((item) => ({
      type,
      uid: nanoid(),
      name: '',
      status: 'done',
      url: item,
      size: 0,
    }));
}

interface ValidateFileListParams {
  allowEmpty?: boolean;
  emptyError?: string;
  limitSize?: number;
  fileList?: UploadFile[];
  fileType?: string[];
  fileTypeError?: string;
}

export function validateFileList({
  fileList = [],
  limitSize = 1024 * 1024 * 5,
  fileType = [
    'image/png',
    'image/jpeg',
    'image/jpg',
  ],
  allowEmpty = false,
  emptyError = '请选择png、jpg、jpeg格式图片',
  fileTypeError = '请选择png、jpg、jpeg格式图片',
}: ValidateFileListParams): Promise<string | void> {
  if (!allowEmpty && !(Array.isArray(fileList) && fileList.length > 0)) {
    return Promise.reject(emptyError);
  }

  for (let i = 0; i < fileList.length; i++) {
    const item = fileList[i];
    if (item.originFileObj) {
      if (item.originFileObj.size > limitSize) {
        const sizeBase = [
          'K',
          'KB',
          'MB',
          'GB',
          'TB',
        ];
        const base = (Math.log2(limitSize) / Math.log2(1024)) >>> 0;
        const sizeDescription = limitSize / Math.pow(1024, base);
        const baseDescription = sizeBase[Math.min(base, sizeBase.length - 1)];

        return Promise.reject(`文件超过${sizeDescription}${baseDescription}`);
      }

      if (fileType.length > 0 && !fileType.includes(item.originFileObj.type)) {
        return Promise.reject(fileTypeError);
      }
    }
  }

  return Promise.resolve();
}

interface UploadFileListToOssParams {
  fileList: UploadFile[];
  obsConfigId: number;
  fileName?: string;
}

export async function uploadFileListToCdn({
  fileList,
  fileName = '',
  obsConfigId,
}: UploadFileListToOssParams): Promise<string[]> {
  if (!Array.isArray(fileList)) {
    return [];
  }

  const result: string[] = [];

  for (let i = 0; i < fileList.length; i++) {
    const fileItem = fileList[i];
    if (fileItem.url) {
      result.push(fileItem.url);
    } else if (fileItem.originFileObj) {
      const fileUrl = await singleUpload({ obsConfigId }, fileItem.originFileObj, { fileName });
      fileItem.url = fileUrl;
      result.push(fileUrl);
    }
  }

  return result;
}
