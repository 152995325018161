import { Button, message } from 'antd';
import { destroyAllModal, renderModal } from 'egenie-common';
import type { PaginationData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, NormalProgramme, request } from 'egenie-utils';
import React from 'react';
import { getOwner } from '../../utils';
import { ExchangeFlag } from './exchangeFlag';
import { SyncShopModal } from './syncShopModal';
import type { MainItem } from './types';

export class Store {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    getOwner()
      .then((data) => this.normalProgramme.filterItems.addDict({ ownerId: data }));
  }

  public mainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '同步店铺',
        permissionId: '721',
        handleClick: () => {
          renderModal(
            <SyncShopModal
              callback={((params) => {
                return request({
                  url: '/api/cloud/baseinfo/rest/shop/batch/sync/erp/shop',
                  method: 'post',
                  data: params.ownerId,
                })
                  .then(() => {
                    this.mainSubStructureModel.onQuery();
                    message.success('同步成功');
                    destroyAllModal();
                  });
              })}
              onCancel={destroyAllModal}
              ownerData={this.normalProgramme.filterItems.dict.ownerId ?? []}
            />
          );
        },
      },
    ],
    grid: {
      columns: [
        {
          name: '操作',
          key: 'operate',
          width: 120,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <Button
                onClick={() => {
                  this.exchangeFlag(row);
                }}
                size="small"
                type="primary"
              >
                更新品牌信息
              </Button>
            );
          },
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 200,
        },
        {
          key: 'brandName',
          name: '品牌名称',
          width: 200,
        },
        {
          key: 'shopName',
          name: '店铺名称',
          width: 200,
        },
        {
          key: 'logoUrl',
          name: '品牌logo',
          width: 60,
          formatter: ({ row }) => <ImgFormatter value={row.logoUrl}/>,
        },
        {
          key: 'logisticNodeName',
          name: '店铺发货时机',
          width: 150,
        },
        {
          key: 'platformTypeName',
          name: '平台类型',
          width: 80,
        },
        {
          key: 'isEnabledName',
          name: '是否启用',
          width: 80,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'shopId',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/shopManage/index',
    },
    pageId: '60015',

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/baseinfo/rest/shop/find/by/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        field: 'ownerId',
        label: '货主',
        type: 'select',
      },
      {
        field: 'shopName',
        label: '店铺名称',
        type: 'input',
      },
    ],
    handleSearch: this.mainSubStructureModel.gridModel.onQuery,
  });

  public exchangeFlag = (mainItem: MainItem) => {
    renderModal(
      <ExchangeFlag
        callback={(async(data) => {
          await request({
            url: '/api/cloud/baseinfo/rest/shop/brandInfo',
            method: 'post',
            data,
          });

          destroyAllModal();
          message.success('更新成功');
          this.mainSubStructureModel.gridModel.resetAll();
          this.mainSubStructureModel.onRefresh();
        })}
        mainItem={{
          shopId: mainItem.shopId,
          brandName: mainItem.brandName,
          logoUrl: mainItem.logoUrl,
        }}
        onCancel={destroyAllModal}
      />
    );
  };
}
